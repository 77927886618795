import React from 'react'
import { useTranslation } from 'react-i18next'
import { StaticImage } from 'gatsby-plugin-image'
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share'

import Layout from 'components/Layout'
import Seo from 'components/Seo'

import {
  PostTitle,
  PostContainer,
  PostImgContainer,
  PostContents,
  PostAuthor,
  PostDate,
  PostSocialsContainer,
} from 'components/BlogComponents'

const Blog = () => {
  const { t } = useTranslation('post1')
  const url = typeof window !== 'undefined' ? window.location.href : null

  return (
    <Layout>
      <Seo title={t('post1Title')} description={t('post1Paragraph1')} />
      <PostContainer>
        <PostTitle>
          <h1>{t('post1Title')}</h1>
        </PostTitle>
        <PostAuthor>
          <p>{t('post1Author')}</p>
        </PostAuthor>
        <PostDate>
          <p>{t('post1Date')}</p>
        </PostDate>
        <PostSocialsContainer>
          <FacebookShareButton
            url={url}
            quote={t('post1Paragraph1')}
            hashtag="#fluentworlds"
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          <LinkedinShareButton url={url}>
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
          <TwitterShareButton url={url}>
            <TwitterIcon size={32} round />
          </TwitterShareButton>
        </PostSocialsContainer>

        <PostImgContainer>
          <StaticImage
            src="../graphics/introductions.jpg"
            alt="Strangers introducing themselves"
          />
        </PostImgContainer>

        <PostContents>
          <p>{t('post1Paragraph1')}</p>
          <p>{t('post1Paragraph2')}</p>
          <p>{t('post1Paragraph3')}</p>
          <p>{t('post1Paragraph4')}</p>
          <p>{t('post1Paragraph5')}</p>
          <p>{t('post1Paragraph6')}</p>
          <p>{t('post1Paragraph7')}</p>
          <p>{t('post1Paragraph8')}</p>
          <p>{t('post1Paragraph9')}</p>
          <ul>
            <li>{t('post1ListItem1')}</li>
            <li>{t('post1ListItem2')}</li>
            <li>{t('post1ListItem3')}</li>
            <li>{t('post1ListItem4')}</li>
            <li>{t('post1ListItem5')}</li>
            <li>{t('post1ListItem6')}</li>
            <li>{t('post1ListItem7')}</li>
            <li>{t('post1ListItem8')}</li>
            <li>{t('post1ListItem9')}</li>
          </ul>
          <p>{t('post1Paragraph10')}</p>
          <p>{t('post1Paragraph11')}</p>
          <p>{t('post1Paragraph12')}</p>
          <p>
            {t('post1Paragraph13')}
            <a href="https://youtu.be/Y8rfZjWlKbY">{t('post1VideoLinkText')}</a>
          </p>
        </PostContents>
      </PostContainer>
    </Layout>
  )
}

export default Blog
